import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import { Headline, Card, Body, Button } from '@sumup/circuit-ui';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';

import Link from '../Link';

import dataSelector from '~/shared/util/data-selector';
import getHeadingTag from '~/shared/util/get-heading-tag';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

/**
 * Styles
 */

const DATA_SELECTOR = 'cost_list';

const headerStyles = ({ theme }) => css`
  text-align: center;
  margin-bottom: ${theme.spacings.giga};
`;

const wrapperStyles = ({ theme }) => css`
  padding: 0;
  border-radius: ${theme.spacings.kilo};
  overflow: hidden;
`;

const tableStyles = ({ theme }) => css`
  font-size: ${theme.typography.headline.four.fontSize};
  line-height: ${theme.typography.headline.two.lineHeight};
  border-spacing: 0;
  max-width: none;
  min-width: 100%;
  position: relative;
  table-layout: fixed;
  width: auto;

  &::before,
  &::after {
    content: '';
    display: block;
    z-index: 1;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--cui-bg-normal);
    width: 67%;

    ${theme.mq.untilKilo} {
      width: 100%;
    }
  }

  &::after {
    ${theme.mq.kilo} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: var(--cui-bg-accent-strong);
      width: 33%;
    }
  }

  col:first-child {
    width: 67%;
  }

  col:last-child {
    width: 33%;
  }
`;

const tableRowStyles = ({ theme }) => css`
  &:first-child td {
    padding-top: ${theme.spacings.giga};
  }

  &:last-child td {
    padding-bottom: ${theme.spacings.giga};
  }
`;

const tableDataStyles = ({ theme }) => css`
  padding: ${theme.spacings.kilo} ${theme.spacings.giga};
  position: relative;
  z-index: 5;
  margin-bottom: ${theme.spacings.mega};

  ${theme.mq.untilKilo} {
    padding: ${theme.spacings.kilo};

    &:first-child {
      text-align: center;
      width: 100%;

      &:after {
        color: var(--cui-fg-accent);
        font-weight: ${theme.fontWeight.bold};
        margin-top: ${theme.spacings.byte};
        content: attr(data-td);
        display: block;
        text-align: center;
      }
    }

    &:last-child {
      display: none;
    }
  }

  &:nth-child(2) {
    color: var(--cui-fg-on-strong);
    font-weight: ${theme.fontWeight.bold};
    text-align: center;
    width: 25%;
  }
`;

const buttonsContainerStyles = ({ theme }) => css`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacings.byte};
  padding: ${theme.spacings.tera} 0px;
  ${theme.mq.untilKilo} {
    margin-top: 0;
    flex-direction: column;
    height: 100%;
  }
`;

const spacerStyles = ({ theme }) => css`
  display: flex;
  height: unset;
  width: ${theme.spacings.mega};
  ${theme.mq.untilKilo} {
    height: ${theme.spacings.mega};
    width: unset;
  }
`;

/**
 * Styled components
 */

const Header = styled(Headline)(headerStyles);

const Wrapper = styled(Card)(wrapperStyles);

const Table = styled('table')(tableStyles);

const TableRow = styled('tr')(tableRowStyles);

const TableData = styled(Body)(tableDataStyles);

const ButtonsContainer = styled('div')(buttonsContainerStyles);

const Spacer = styled('div')(spacerStyles);

/**
 * A table like list component to display data
 */
const CostList = ({
  costItems: originalCostItems = [],
  headline = '',
  className,
  index,
  name,
  id,
  primaryButtonUrl,
  primaryButtonLabel,
  primaryButtonTrackingId,
  primaryButtonOptimizelyFullStackClickEvents,
  secondaryButtonUrl,
  secondaryButtonLabel,
  secondaryButtonTrackingId,
  secondaryButtonOptimizelyFullStackClickEvents,
}) => {
  const { experiments } = useOptimizelyData();
  const costItems = mapVariations(originalCostItems, experiments);

  if (isEmpty(costItems)) {
    return null;
  }

  const as = getHeadingTag(index);
  const renderButtons = !!secondaryButtonUrl || !!primaryButtonUrl;

  return (
    <Grid
      className={className}
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:cost_list"
    >
      {headline && (
        <Row>
          <Col span="12">
            <Header as={as} size="two">
              {headline}
            </Header>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          span={{ default: 12, kilo: 8, mega: 6 }}
          skip={{ default: 0, kilo: 2, mega: 3 }}
        >
          <Wrapper>
            <Table>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <tbody>
                {costItems.map(({ featureName, featureAttribute }, i) => (
                  <TableRow key={i}>
                    <TableData
                      data-td={featureAttribute}
                      as="td"
                      size="one"
                      data-selector={dataSelector('data_left', DATA_SELECTOR)}
                    >
                      {featureName}
                    </TableData>
                    <TableData
                      as="td"
                      size="one"
                      data-selector={dataSelector('data_right', DATA_SELECTOR)}
                    >
                      {featureAttribute}
                    </TableData>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          </Wrapper>
        </Col>
      </Row>
      {renderButtons && (
        <Row>
          <Col
            span={{ default: 12, kilo: 8, mega: 6 }}
            skip={{ default: 0, kilo: 2, mega: 3 }}
          >
            <ButtonsContainer>
              {!!primaryButtonUrl && (
                <Link
                  href={primaryButtonUrl}
                  trackingId={primaryButtonTrackingId}
                  optimizelyFullStackClickEvents={
                    primaryButtonOptimizelyFullStackClickEvents
                  }
                  trackingContentEntry={{
                    contentType: 'costList',
                    contentEntryName: name,
                    contentEntryId: id,
                  }}
                >
                  <Button variant="primary">{primaryButtonLabel}</Button>
                </Link>
              )}
              {renderButtons && <Spacer>&nbsp;</Spacer>}
              {!!secondaryButtonUrl && (
                <Link
                  href={secondaryButtonUrl}
                  trackingId={secondaryButtonTrackingId}
                  optimizelyFullStackClickEvents={
                    secondaryButtonOptimizelyFullStackClickEvents
                  }
                  trackingContentEntry={{
                    contentType: 'costList',
                    contentEntryName: name,
                    contentEntryId: id,
                  }}
                >
                  <Button variant="secondary">{secondaryButtonLabel}</Button>
                </Link>
              )}
            </ButtonsContainer>
          </Col>
        </Row>
      )}
    </Grid>
  );
};

CostList.propTypes = {
  /**
   * The markdown text.
   */
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  costItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  headline: PropTypes.string,
  index: PropTypes.number,
  primaryButtonUrl: PropTypes.string,
  primaryButtonTrackingId: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  primaryButtonOptimizelyFullStackClickEvents: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
  secondaryButtonTrackingId: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonOptimizelyFullStackClickEvents: PropTypes.string,
};

/**
 * @component
 */
export default CostList;
